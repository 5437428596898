import React, { useEffect, useState } from "react";
import { Input } from "../../Common/index.js";
import Styles from "./Navigation.module.css";
import Button from "../../Common/Button/Button.js";
import { useLocation, useNavigate } from "react-router-dom";
import userSvg from "../../../images/user.svg";

const Navigation = ({ setStockSymbol, setOpenModal }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/") {
      const timeOut = setTimeout(() => setStockSymbol(search), 500);
      return () => clearTimeout(timeOut);
    }
  }, [search, setStockSymbol, pathname]);
  const handleNavigateAbout = () => {
    window.open("https://www.levelfields.ai/about", "_blank");
  };

  return (
    <div className={Styles.Navigation}>
      <div className={Styles.NavigationContent}>
        <div className={Styles.LeftSideNav}>
          <ul className={Styles.NavigationList}>
            <li onClick={() => navigate("/faq")}>FAQ</li>
            <li onClick={() => navigate("/contact-us")}>Contact us</li>
            <li onClick={handleNavigateAbout}>About Us</li>
            <li onClick={() => navigate("/blog")}>News</li>
            <li onClick={() => navigate("/login")}>
              <img src={userSvg} alt="user" width={20} height={20} />
            </li>
          </ul>
        </div>
        <h2 onClick={() => navigate("/")}>Rule of 40</h2>
        <div className={Styles.RightSideNav}>
          {pathname === "/" ? (
            <>
              <div className={Styles.SearchBar}>
                <Input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={"Search Symbol..."}
                />
              </div>
              <div className={Styles.Buttons}>
                <Button
                  style={{
                    height: "40px",
                  }}
                  variant={"black"}
                  onClick={() => setOpenModal((prev) => !prev)}
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg> */}
                  Download
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
